import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import getTrack from 'src/utils/get-track'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { defaultBrokerPricing } from 'src/utils/defaultBrokerPricing'
import { CCard, CCardBody, CFormSelect, CFormInput } from '@coreui/react'
import { cilCarAlt, cilUser, cilGroup, cilLocationPin, cilClock } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import handleErr from 'src/utils/handle-error'
import { metersToMiles } from 'src/utils/get-miles'
import Capitalize from 'src/utils/capitalize'
import OlMap from './OlMap'
import { minutesToTimes } from 'src/utils/handle-date'

const DistancePriceWidget = ({ companySettings, vehicleType }) => {
  const [startAddress, setStartAddress] = useState('')
  const [endAddress, setEndAddress] = useState('')
  const [startCoords, setStartCoords] = useState(null)
  const [endCoords, setEndCoords] = useState(null)
  const [distance, setDistance] = useState(null)
  const [duration, setDuration] = useState(null)
  const [price, setPrice] = useState(null)
  const [vehicleTypeState, setVehicleTypeState] = useState('sedan')
  const [riderCount, setRiderCount] = useState(1)
  const [isCalculating, setIsCalculating] = useState(false)

  const vehicleOptions = [
    { vehicleType: 'sedan', maxRiders: 4, basePrice: 20 },
    { vehicleType: 'miniVan', maxRiders: 6, basePrice: 25 },
    { vehicleType: 'van', maxRiders: 8, basePrice: 37 },
    { vehicleType: 'bus', maxRiders: 17, basePrice: 50 },
  ]

  // const calculateFare = (vehicleType, distance) => {
  //   const basePrice = defaultBrokerPricing[`${vehicleType}`]
  //   const extraMilePrice = defaultBrokerPricing[`${vehicleType}Extramile`]
  //   const milesIncluded = defaultBrokerPricing.milesIncluded

  //   if (basePrice !== undefined && extraMilePrice !== undefined) {
  //     const totalCost =
  //       distance > milesIncluded
  //         ? basePrice + (distance - milesIncluded) * extraMilePrice
  //         : basePrice
  //     return Number(totalCost)
  //   }

  //   return Number(0)
  // }

  const calculateFareNew = (basePrice, distance, riderCount) => {
    return (1.2 * distance * riderCount + basePrice).toFixed(2)
  }

  const handleSelect = async (address, setCoords, setAddress) => {
    setAddress(address)
    try {
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])
      setCoords([latLng.lng, latLng.lat]) // Setting coordinates in [lng, lat] format
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const selectedVehicle = vehicleOptions.find((v) => v.vehicleType === vehicleTypeState)
    if (riderCount > selectedVehicle?.maxRiders) {
      const suitableVehicle = vehicleOptions.find((v) => riderCount <= v.maxRiders)
      if (suitableVehicle) {
        setVehicleTypeState(suitableVehicle.vehicleType)
      }
    }
  }, [riderCount])

  useEffect(() => {
    const selectedVehicle = vehicleOptions.find((v) => v.vehicleType === vehicleTypeState)
    if (riderCount > selectedVehicle.maxRiders) {
      // const suitableVehicle = vehicleOptions.find((v) => riderCount >= v.maxRiders)
      // if (suitableVehicle) {
      setRiderCount(selectedVehicle.maxRiders)
      // }
    }
  }, [vehicleTypeState])

  const calculateDistanceAndPrice = async () => {
    if (!startCoords || !endCoords) return
    try {
      // const point1 = [-87.75136608077267, 41.92429003497408]
      // const point2 = [-87.76125806730694, 41.92406652295526]
      setIsCalculating(true)
      setDistance(null)
      setDuration(null)
      const track = await getTrack(startCoords, endCoords)
      const distanceInMiles = metersToMiles(track.routes[0]?.distance)
      const durationTime = minutesToTimes(Number(track.routes[0]?.duration))
      // const distanceInMiles = track.routes[0].distance / 1609
      setIsCalculating(false)
      setDistance(distanceInMiles)
      setDuration(durationTime)
      const selectedVehicle = vehicleOptions.find((v) => v.vehicleType === vehicleTypeState)
      const calculatedPrice = calculateFareNew(
        selectedVehicle.basePrice,
        distanceInMiles,
        riderCount,
      )
      setPrice(calculatedPrice)
    } catch (err) {
      setIsCalculating(false)
      handleErr({ err })
    }
  }

  useEffect(() => {
    calculateDistanceAndPrice()
  }, [startCoords, endCoords, vehicleTypeState, riderCount])

  return (
    <div className="d-flex align-items-center w-100 vh-100 p-1 overflow-hidden">
      <CCard className="d-flex align-items-center m-1 vw-100 mw-555 mx-auto">
        {/* <CCardHeader>
        <h3>Distance & Price Calculator</h3>
      </CCardHeader> */}
        <CCardBody className="w-100 p-1 m">
          {/* <CForm> */}
          {startCoords && endCoords ? (
            <OlMap
              rounded={true}
              coords={[
                {
                  id: `${startCoords[0]}-${startCoords[1]}`,
                  name: '1',
                  location: { lng: startCoords[0], lat: startCoords[1] },
                },
                {
                  id: `${endCoords[0]}-${endCoords[1]}`,
                  name: '2',
                  location: { lng: endCoords[0], lat: endCoords[1] },
                  startRoute: true,
                  driver: 'x',
                },
              ]}
              height={`303px`}
            />
          ) : (
            ''
          )}

          <PlacesAutocomplete
            value={startAddress}
            onChange={setStartAddress}
            onSelect={(address) => handleSelect(address, setStartCoords, setStartAddress)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className={`w-100 ${startCoords ? 'mb-3' : ''}`}>
                {/* <CFormLabel>Start Address:</CFormLabel> */}
                <input
                  size=""
                  style={{ width: '100%' }}
                  {...getInputProps({
                    placeholder: 'Where To ...',
                    className: 'p-3 rounded shadow',
                  })}
                />
                {loading && <div className="p-1 fw-bold text-secondary">Please Wait...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    className="google-suggestion py-3 px-2 c-pointer rounded fw-bold text-secondary"
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
              </div>
            )}
          </PlacesAutocomplete>

          {startCoords ? (
            <>
              <PlacesAutocomplete
                value={endAddress}
                onChange={setEndAddress}
                onSelect={(address) => handleSelect(address, setEndCoords, setEndAddress)}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className="mb-3">
                    <CFormInput
                      {...getInputProps({
                        placeholder: 'Where To ...',
                        className: 'p-3 rounded shadow',
                      })}
                    />
                    {loading && <div className="p-1 fw-bold text-secondary">Please Wait...</div>}
                    {suggestions.map((suggestion) => (
                      <div
                        className="google-suggestion py-3 px-2 c-pointer rounded fw-bold text-secondary"
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.placeId}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    ))}
                  </div>
                )}
              </PlacesAutocomplete>
            </>
          ) : null}
          {startCoords && endCoords ? (
            <>
              <div className="mb-3 row">
                <div className="w-fit shadow rounded d-flex align-items-center">
                  <div className="ms-2 mx-auto text-center">
                    <CIcon
                      size="lg"
                      icon={riderCount === 1 ? cilUser : cilGroup}
                      className="mx-auto"
                    />
                  </div>
                  <CFormSelect
                    className="ms-1 border-0 text-center fs-22"
                    value={riderCount}
                    onChange={(e) => setRiderCount(Number(e.target.value))}
                  >
                    {[...Array(77).keys()].map((i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </CFormSelect>
                </div>

                <div className="w-fit shadow rounded d-flex align-items-center">
                  {/* <CFormLabel>
                      <CIcon icon={cilCarAlt} size="sm" className="ms-n2 me-1" /> Select Vehicle
                      Type:
                    </CFormLabel> */}
                  <CFormSelect
                    className="ms-0 border-0 text-center"
                    value={vehicleTypeState}
                    onChange={(e) => setVehicleTypeState(e.target.value)}
                  >
                    {vehicleOptions.map((vehicle) => (
                      <option
                        key={vehicle.vehicleType}
                        value={vehicle.vehicleType}
                        // disabled={riderCount > vehicle.maxRiders}
                      >
                        {Capitalize(vehicle.vehicleType)}
                      </option>
                      // <option
                      //   key={vehicle.vehicleType}
                      //   value={vehicle.vehicleType}
                      //   disabled={riderCount > vehicle.maxRiders}
                      // >
                      //   {Capitalize(vehicle.vehicleType)} (Max {vehicle.maxRiders} riders) - Base
                      //   Price: ${vehicle.basePrice}
                      // </option>
                    ))}
                  </CFormSelect>
                </div>

                <div className="w-fit d-flex align-items-center">
                  <CIcon size="lg" icon={cilLocationPin} className="mx-auto" />
                  {distance}mi
                </div>
                <div className="w-fit d-flex align-items-center ">
                  <CIcon size="lg" icon={cilClock} className="mx-1" />
                  {duration} min
                </div>
              </div>
            </>
          ) : null}

          {/* <CButton color="primary" className="mt-3" onClick={calculateDistanceAndPrice}>
            Calculate
          </CButton> */}

          {isCalculating ? (
            <strong className="p-1 fw-bold text-secondary">Please Wait...</strong>
          ) : (
            distance &&
            duration && (
              <button className="w-100 rounded shadow-hover grad-success">
                REQUEST <div className="fs-22 fw-bold text-success">${price}</div>
              </button>
            )
          )}
          {/* </CForm> */}
        </CCardBody>
      </CCard>
    </div>
  )
}

// Define the expected prop types
DistancePriceWidget.propTypes = {
  companySettings: PropTypes.shape({
    pricePerMile: PropTypes.number,
  }),
  vehicleType: PropTypes.string,
}

export default DistancePriceWidget
