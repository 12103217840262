import React, { useEffect, useState } from 'react'
import DistancePriceWidget from './DistancePriceWidget'
import { axiosPrivate } from 'src/services/api'
import FullLoader from 'src/components/FullLoader'

const WidgetContainer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const [companySettings, setCompanySettings] = useState(null)
  const params = new URLSearchParams(window.location.search)
  const companyId = params.get('companyId')
  const vehicleType = params.get('vehicleType')

  useEffect(() => {
    if (companyId) {
      const fetchCompanySettings = async () => {
        try {
          const axPriv = await axiosPrivate()
          const { data } = await axPriv.get(`/companies/company/${companyId}`)
          setIsLoading(false)
          setCompanySettings(data)
        } catch (error) {
          setIsLoading(false)
          setError(error?.response?.data?.errors ?? 'Something went wrong')
          console.error('Error fetching company settings:', error)
        }
      }
      fetchCompanySettings()
    } else {
      setIsLoading(false)
      // setError('Company Id is required to load the widget')
    }
  }, [companyId])

  console.log('check=>', isLoading, error, !isLoading && error)
  if (isLoading) {
    return <FullLoader />
  } else if (!isLoading && error) {
    return <p>{error}</p>
  } else return <DistancePriceWidget companySettings={companySettings} vehicleType={vehicleType} />
}

export default WidgetContainer
