import socket from 'src/services/socket'
import {
  // CHANGE_TRIP_STATUS_START,
  CHANGE_TRIP_STATUS_SUCCESS,
  DELETE_QUICKNOTES,
  GET_QUICKNOTES,
  PUSH_DRIVER_LOC_START,
  PUSH_DRIVER_LOC_SUCCESS,
  TOGGLE_SIDEBAR,
  TOGGLE_UNFOLDABLE,
  UPDATE_SEARCHTERMS,
  UPDATE_SIGNATURE_FAIL,
  UPDATE_TRIP_SUCCESS,
  UPDATE_USER_SETTINGS,
  // PUSH_DRIVER_LOC_FAIL,
} from '../constants'

import { axiosPrivate, axiosPublic, BASE_URL } from '../services/api'
import { toast } from 'react-toastify'
import handleIDB from 'src/utils/handle-indexed-db'
import handleErr from '../utils/handle-error'
// import handleIDB from '../utils/handle-indexed-db'

// CHANGE TRIP STATUS
export const startStopTripAction = (args) => async (dispatch) => {
  // dispatch({ type: CHANGE_TRIP_STATUS_START })
  // toast.warn(`Please Wait...`)
  // const newTripStatus = JSON.parse(JSON.stringify(args))
  // delete newTripStatus.cb
  // const { id: _id } = args
  // const model = 'Trip'
  const { newTripData, userInfo, cb } = args //deep clone
  try {
    const url = `${BASE_URL}/trips/startStopTrip`
    // const url = `${BASE_URL}/trips/statuses/${id}`
    // const tripIdb = await handleIDB.getVal({ model, _id: args.id })

    // if (!args.customerReady && trip) trip.driver = null
    // const data = trip ? { ...trip, ...args } : ''
    // const error = trip ? false : true
    // const type = data ? CHANGE_TRIP_STATUS_SUCCESS : CHANGE_TRIP_STATUS_FAIL
    // const payload = data || 'Trip Not Found'
    // const payload = data || err.message || err.response?.data?.errors
    // console.log(trip, data)
    // if (tripIdb) await handleIDB.put({ model, data: { ...tripIdb, ...args.trip } })
    // if (data) await handleIDB.put({ model, data })
    // dispatch({ type, payload })
    // if (args.cb)
    //   args.cb(
    //     data
    //       ? cbData({ data: { ...data, error, isOffLine: true } })
    //       : { payload: ['Error'], error: true },
    //   )
    // const updatedData = JSON.parse(JSON.stringify(args)) //deep clone
    // delete updatedData.cb
    const axPriv = await axiosPrivate()
    const { data: dbPayload } = await axPriv.put(url, { newTripData, userInfo })
    // const { userInfo } = updatedData
    // if (!socket.connected) socket.connect()
    // socket.emit('room', 'company_' + userInfo.company._id)
    // socket.emit(`startStopTrips`, { newTripData, userInfo })
    // console.log(updatedData)
    // socket.emit(`startStopTrips${userInfo.company._id}`, updatedData)
    // socket.once(`tripStartedStopped${userInfo?._id}`, (dbPayload) => {
    // dispatch({ type: UPDATE_TRIP_SUCCESS, payload })
    const { success } = dbPayload || {}
    if (!success) return toast.error(`Unknown Error !!!`)
    // toast.success(`Ready!`)
    dispatch({ type: UPDATE_TRIP_SUCCESS, payload: dbPayload })
    cb && cb({ success })
    // })
    // dispatch({ type: CHANGE_TRIP_STATUS_SUCCESS, payload: {} })
    // console.log(dataFromServer)
  } catch (err) {
    if (err?.code === 'ERR_NETWORK') {
      return toast.error(
        `Weak Signal ${
          navigator?.connection?.downlink < 1 ? `${navigator?.connection?.downlink}Mbs` : ''
        }`,
      )
    }
    cb && cb({})
    dispatch({ type: CHANGE_TRIP_STATUS_SUCCESS, payload: {} })
    // const trip = await handleIDB.getVal({ model, _id })
    // if (!statusPostData.customerReady) trip.driver = null
    // const data = trip ? { ...trip, ...statusPostData } : ''
    // const error = trip ? false : true
    // const type = data ? CHANGE_TRIP_STATUS_SUCCESS : CHANGE_TRIP_STATUS_FAIL
    // const payload = data || err.message || err.response?.data?.errors
    // if (data) await handleIDB.put({ model, data })
    // dispatch({ type, payload })
    // if (args.cb)
    //   args.cb(
    //     data ? cbData({ data: { ...data, error, isOffLine: true } }) : { payload, error: true },
    //   )
    // console.log(err)
    handleErr({ err })
    // if (args.cb && err?.code !== 'ERR_NETWORK')
    //   args.cb({ msg: err?.message || err.response?.data?.errors[0], error: true })
  }
}
// export const startStopTripAction = (args) => async (dispatch) => {
//   dispatch({ type: CHANGE_TRIP_STATUS_START })
//   // toast.warn(`Please Wait...`)
//   // const newTripStatus = JSON.parse(JSON.stringify(args))
//   // delete newTripStatus.cb
//   // const { id: _id } = args
//   // const model = 'Trip'
//   const { newTripData, userInfo, cb } = args //deep clone
//   try {
//     // const url = `${BASE_URL}/trips/statuses/${_id}`
//     // const tripIdb = await handleIDB.getVal({ model, _id: args.id })

//     // if (!args.customerReady && trip) trip.driver = null
//     // const data = trip ? { ...trip, ...args } : ''
//     // const error = trip ? false : true
//     // const type = data ? CHANGE_TRIP_STATUS_SUCCESS : CHANGE_TRIP_STATUS_FAIL
//     // const payload = data || 'Trip Not Found'
//     // const payload = data || err.message || err.response?.data?.errors
//     // console.log(trip, data)
//     // if (tripIdb) await handleIDB.put({ model, data: { ...tripIdb, ...args.trip } })
//     // if (data) await handleIDB.put({ model, data })
//     // dispatch({ type, payload })
//     // if (args.cb)
//     //   args.cb(
//     //     data
//     //       ? cbData({ data: { ...data, error, isOffLine: true } })
//     //       : { payload: ['Error'], error: true },
//     //   )
//     // const updatedData = JSON.parse(JSON.stringify(args)) //deep clone
//     // delete updatedData.cb
//     // const axPriv = await axiosPrivate()
//     // const { dataFromServer } = await axPriv.put(`/trips/statuses/${args.id}`, updatedData)
//     // const { userInfo } = updatedData
//     if (!socket.connected) socket.connect()
//     // socket.emit('room', 'company_' + userInfo.company._id)
//     socket.emit(`startStopTrips`, { newTripData, userInfo })
//     // console.log(updatedData)
//     // socket.emit(`startStopTrips${userInfo.company._id}`, updatedData)
//     socket.once(`tripStartedStopped${userInfo?._id}`, (dbPayload) => {
//       // dispatch({ type: UPDATE_TRIP_SUCCESS, payload })
//       const { success } = dbPayload
//       if (!success) return toast.error(`Unknown Error !!!`)
//       // toast.success(`Ready!`)
//       cb && cb()
//     })
//     // dispatch({ type: CHANGE_TRIP_STATUS_SUCCESS, payload: {} })
//     // console.log(dataFromServer)
//   } catch (err) {
//     if (err?.code === 'ERR_NETWORK') {
//       return toast.error(
//         `Weak Signal ${
//           navigator?.connection?.downlink ? `${navigator?.connection?.downlink}Mbs` : ''
//         }`,
//       )
//     }
//     cb && cb()
//     dispatch({ type: CHANGE_TRIP_STATUS_SUCCESS, payload: {} })
//     // const trip = await handleIDB.getVal({ model, _id })
//     // if (!statusPostData.customerReady) trip.driver = null
//     // const data = trip ? { ...trip, ...statusPostData } : ''
//     // const error = trip ? false : true
//     // const type = data ? CHANGE_TRIP_STATUS_SUCCESS : CHANGE_TRIP_STATUS_FAIL
//     // const payload = data || err.message || err.response?.data?.errors
//     // if (data) await handleIDB.put({ model, data })
//     // dispatch({ type, payload })
//     // if (args.cb)
//     //   args.cb(
//     //     data ? cbData({ data: { ...data, error, isOffLine: true } }) : { payload, error: true },
//     //   )
//     // console.log(err)
//     handleErr({ err })
//     // if (args.cb && err?.code !== 'ERR_NETWORK')
//     //   args.cb({ msg: err?.message || err.response?.data?.errors[0], error: true })
//   }
// }

// PUSH TRIP LOCATION

export const pushDriverLoc =
  ({ trip, note, location }) =>
  async (dispatch) => {
    dispatch({ type: PUSH_DRIVER_LOC_START })
    // const model = 'Trip'
    // const locationData = {
    //   location,
    //   date: new Date(),
    //   note,
    // }
    try {
      // const tripToUpdate = (await handleIDB.getVal({ model, _id: trip._id })) || trip
      // tripToUpdate.driverLocations.push(locationData)
      // await handleIDB.put({ model, data: tripToUpdate })
      // const tripIdb = await handleIDB.getVal({ model, _id: trip._id })
      // const trip =
      //   (await handleIDB.getVal({ model, _id })) ||
      //   handleIDB.localDB.dataToSave.find((trip) => trip.data._id === _id)?.data
      // console.log(await handleIDB.getVal({ model, _id }), _id)
      // if (trip) {
      // trip.driverLocations.push(locationData)
      // await handleIDB.put({ model, data: trip })
      // return dispatch({ type: PUSH_DRIVER_LOC_SUCCESS, payload: trip })
      // }
      // } else {
      const axPriv = await axiosPrivate()
      const { data } = await axPriv.put(`/trips/locations/${trip._id1}`, {})
      // const { data } = await axPriv.put(`/trips/locations/${trip._id}`, locationData)
      // if (data.success) {
      // }
      dispatch({ type: PUSH_DRIVER_LOC_SUCCESS, payload: {} })
      // dispatch({ type: PUSH_DRIVER_LOC_SUCCESS, payload: data.trip })
      // }

      // if (!data?.success) throw new Error(data?.errors[0] || 'Something went wrong')
    } catch (err) {
      handleErr({ err, dispatch })
    }
  }

export const getRouteData = async (point1, point2) => {
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/gmaps/routeapi/`, { params: { point1, point2 } })
    if (data?.success) return data
    return ''
  } catch (err) {
    handleErr({ err })
    return null
  }
}

export const getGeoCodeData = async ({ fullAddress, isRegistration }) => {
  try {
    const axPubPriv = isRegistration ? axiosPublic : await axiosPrivate()
    const geoCodeRoute = isRegistration ? `/gmaps/geocodeonregister/` : `/gmaps/geocode/`
    const { data = {} } = await axPubPriv.get(geoCodeRoute, { params: { fullAddress } })
    return data
  } catch (err) {
    handleErr({ err })
    return null
  }
}

export const addressValidation = async ({ address }) => {
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.get(`/gmaps/addressvalidation/`, { params: { address } })
    // console.log(data)
    return data
  } catch (err) {
    handleErr({ err })
    return null
  }
}

export const verifyCaptchaToken = async ({ token }) => {
  try {
    const { data } = await axiosPublic.post(`/users/verify-captcha-token/`, {
      token,
    })
    return data
  } catch (err) {
    handleErr({ err })
  }
}

export const getQuickNotes =
  ({ authorId, cb }) =>
  async (dispatch) => {
    try {
      const axPriv = await axiosPrivate()
      const { data } = await axPriv.get(`/users/quicknotes/`, { authorId })
      const { quickNotes = [] } = data || {}
      dispatch({ type: GET_QUICKNOTES, payload: quickNotes })
      cb && cb({ quickNotes })
    } catch (err) {
      handleErr({ err })
      dispatch({ type: GET_QUICKNOTES, payload: [] })
    }
  }

export const deleteQuickNotes = (payload) => async (dispatch) => {
  try {
    const axPriv = await axiosPrivate()
    const { content } = payload
    const { data } = await axPriv.delete(`/users/deletequicknotes/${content}`)
    if (data?.success) {
      toast.success('Note deleted !!!')
      dispatch({ type: DELETE_QUICKNOTES, payload: content })
    } else {
      toast.error('Error deleting note, please try again later')
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error deleting note, please try again later` },
    })
    return []
  }
}

export const uploadAvatarAction = (payload) => async (dispatch) => {
  const { userId, srcBase64, cb } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.post(`/users/uploadavatar`, payload)

    const { success, msg } = data || {}
    if (success) {
      handleIDB.put({
        model: 'Avatar',
        _id: userId,
        data: srcBase64,
      })
      toast.success(msg || 'Image Uploaded Successfully', { toastId: 'avatarSuccess' })
    }
    //  else {
    //   toast.error('Error Uploading Image')
    // }
    cb && cb()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Uploading Avatar, please try again later` },
    })
  }
}

export const getAvatarAction = async ({ userId }) => {
  const model = 'Avatar'
  try {
    const dataIDB = '' //await handleIDB.getVal({ model, _id: userId })
    if (dataIDB) {
      return dataIDB
    } else {
      const axPriv = await axiosPrivate()
      const { data } = await axPriv.get(`/users/avatar/${userId}`)
      const { success, srcBase64 } = data
      if (success && srcBase64) {
        handleIDB.put({
          model,
          _id: userId,
          data: srcBase64,
        })
        return srcBase64
      }
      return ''
    }
  } catch (err) {
    handleErr({ err })
    const dataIDB = await handleIDB.getVal({ model, _id: userId })
    if (dataIDB) {
      return dataIDB
    }
    return ''
  }
}

export const removeAvatarAction = async ({ userId, cb }) => {
  try {
    const model = 'Avatar'
    handleIDB.delete({ model, _id: userId })
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.delete(`/users/removeavatar/${userId}`)
    const { success } = data
    if (success) {
      cb && cb()
    }
    toast.warning('Image Removed Successfully')
  } catch (err) {
    handleErr({ err })
    return ''
  }
}

export const sendEmailAction = (payload) => async (dispatch) => {
  const { isError, noAlert, successMsg } = payload
  !isError &&
    !noAlert &&
    toast.warn(`Sending, Please Wait...`, {
      toastId: 'email',
      autoClose: false,
    })
  const { cb } = payload
  try {
    const axPriv = await axiosPrivate()
    const { data } = await axPriv.post('/users/sendemail', payload)
    const { success, msg, err } = data
    if (success) {
      if (!isError) {
        toast.dismiss()
        setTimeout(() => {
          !noAlert &&
            toast.success(msg || successMsg || 'Email Sent !', {
              toastId: 'emailsuccess',
            })
        }, 707)
      }

      cb && cb(data)
    } else {
      handleErr({
        err,
        alert: isError
          ? ''
          : {
              msg: `Error Sending Email, Please try again`,
            },
        customFunc: () => {
          cb && cb({ success: false })
        },
      })
    }
  } catch (err) {
    handleErr({
      err,
      alert: {
        msg: isError ? '' : `Error Sending Email, Please try again`,
      },
      customFunc: () => {
        cb && cb({ success: false })
      },
    })
  }
}

export const toggleSideBar = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload,
    })
  } catch (err) {
    handleErr({ err })
  }
}

export const toggleUnfoldable = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_UNFOLDABLE,
      payload,
    })
  } catch (err) {
    handleErr({ err })
  }
}

export const updateSearchTermsAction = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SEARCHTERMS,
      payload,
    })
  } catch (err) {
    handleErr({ err })
  }
}

export const updateUserSettings = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_SETTINGS,
      payload,
    })
  } catch (err) {
    handleErr({ err })
  }
}

export const getSignatureData = async (payload) => {
  try {
    const { id } = payload
    const axPubPriv = await axiosPrivate()
    const { data = {} } = await axPubPriv.get(`/users/signature?id=${id}`)
    return data
  } catch (err) {
    handleErr({ err })
    return null
  }
}

//to app.js with socket
export const updateSignature = (payload) => async (dispatch) => {
  try {
    const { id, signature, userInfo, cb } = payload

    if (!socket.connected && userInfo?._id) socket.connect()
    socket.emit(`updateSignature`, { id, signature, userInfo })
    socket.once(`signatureUpdated${userInfo._id}`, (dbPayload) => {
      const { success, errors } = dbPayload
      toast.dismiss()
      setTimeout(() => {
        if (success) {
          toast.success(`Signature Updated`, { toastId: userInfo._id })
          cb && cb()
        } else {
          handleErr({
            err: errors,
            dispatch,
            alert: true,
            reduxType: UPDATE_SIGNATURE_FAIL,
          })
        }
      }, 707)
    })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: {
        msg: `Error Updating Signature`,
      },
      reduxType: UPDATE_SIGNATURE_FAIL,
    })
    // dispatch({ type: UPDATE_INSPECTION_FAIL, payload: err.response?.data?.errors })
  }
}
