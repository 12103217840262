import { updateTripAction } from 'src/actions/adminActions'
import { BASE_URL } from '../services/api'
import { uInfo, userIsTripDriver } from './helpers'
import handleErr from './handle-error'
const cacheName = 'xd_v1'
const handleIDB = {
  localDB: {
    name: 'xdDB',
    models: [
      'Auth',
      'Trip',
      'Searchterms',
      'Tripsreport',
      'User',
      'Destination',
      'Broker',
      'BrokerPricing',
      'Insurance',
      'Reminder',
      'Vehicle',
      'Inspection',
      'Payment',
      'Transaction',
      'Company',
      'Facility',
      'Coupon',
      'Avatar',
      'Device',
      'Product',
      'Track',
    ],
    data: '',
    dataToSave: [],
  },
  openDB: () =>
    new Promise((resolve, reject) => {
      const dbOpen1 = indexedDB.open(handleIDB.localDB.name)

      dbOpen1.onsuccess = function (e) {
        const dbV1 = e.target.result
        const version = parseInt(dbV1.version)
        const noModels = handleIDB.localDB.models.filter((m) => !dbV1.objectStoreNames.contains(m))
        const oldModels = [...dbV1.objectStoreNames].filter(
          (m) => !handleIDB.localDB.models.some((dbm) => dbm === m),
        )

        if (!noModels?.length && !oldModels?.length) {
          handleIDB.localDB.data = dbOpen1.result
          resolve(dbOpen1.result)
        } else {
          // if there's no "models" store
          dbV1.close()
          const dbOpen2 = indexedDB.open(handleIDB.localDB.name, version + 1)
          dbOpen2.onupgradeneeded = (e) => {
            console.log(e)
            console.log(`upgrading db from ${e.oldVersion} to ${e.newVersion}...`)
            // This should only execute if there's a need to create/update db.
            const dbUpgraded = e.target.result
            // if there's no "models" store
            noModels.forEach((m) => dbUpgraded.createObjectStore(m)) // create it
            oldModels.forEach((m) => dbUpgraded.deleteObjectStore(m)) // delete it
          }

          dbOpen2.onsuccess = function (e) {
            handleIDB.localDB.data = e.target.result
            // e.target.result.close()
            resolve(e.target.result)
          }
        }
      }

      dbOpen1.onerror = (error) => {
        reject(null)
        console.error('IndexedDB error:', error)
      }

      // dbOpen1.onupgradeneeded = (e) => {
      //   console.log(e)
      //   console.log(`upgrading db from ${e.oldVersion} to ${e.newVersion}...`)
      //   // This should only execute if there's a need to create/update db.
      //   handleIDB.localDB.data = dbOpen.result
      //   for (const model of handleIDB.localDB.models) {
      //     // dbOpen.result.createObjectStore(model, { keyPath: 'id' })
      //     // dbOpen.result.createObjectStore(model)
      //     if (!handleIDB.localDB.data.objectStoreNames.contains(model)) {
      //       // if there's no "models" store
      //       handleIDB.localDB.data.createObjectStore(model) // create it
      //     }
      //   }
      // }
      // dbOpen1.onversionchange = () => {
      //   handleIDB.localDB.data = dbOpen.result

      //   for (const model of handleIDB.localDB.models) {
      //     // dbOpen.result.createObjectStore(model, { keyPath: 'id' })
      //     // dbOpen.result.createObjectStore(model)
      //     if (!handleIDB.localDB.data.objectStoreNames.contains(model)) {
      //       // if there's no "models" store
      //       handleIDB.localDB.data.createObjectStore(model) // create it
      //     }
      //   }
      //   dbOpen1.result.close()
      //   // alert('The IndexedDB database has been upgraded.\nPage will reload...')
      //   window.location.reload()
      // }
      // This will execute each time the database is opened.
    }),
  getIdbObjStore: ({ model, mode }) =>
    new Promise(async (resolve) => {
      try {
        const localDBdata = await handleIDB.openDB()
        if (localDBdata) {
          // if (!handleIDB.localDB.data.objectStoreNames.contains(model)) {
          //   // if there's no "models" store
          //   handleIDB.localDB.data.createObjectStore(model) // create it
          // }
          const objStore = localDBdata.transaction(model, mode).objectStore(model)
          resolve(objStore)
        } else {
          resolve(null)
        }
      } catch (err) {
        handleErr({ err })
        resolve(null)
      }
    }),
  getVal: ({ model, _id }) =>
    new Promise(async (resolve, reject) => {
      try {
        const objStore = await handleIDB.getIdbObjStore({ model, mode: 'readonly' })
        if (objStore) {
          const req = objStore.get(_id)
          req.onsuccess = (e) => resolve(e?.target?.result)
          req.onerror = (err) => {
            handleErr({ err })
            reject(null)
          }
        }
      } catch (err) {
        handleErr({ err })
        reject(null)
      }
    }),
  getAll: ({ model, range }) =>
    new Promise(async (resolve, reject) => {
      try {
        const modelObj = await handleIDB.getIdbObjStore({ model, mode: 'readonly' })
        const request = range ? modelObj.getAll(range.start, range.end) : modelObj.getAll()
        request.onsuccess = (e) => resolve(e.target.result)
        request.onerror = (err) => reject([])
      } catch (error) {
        reject('')
      }
    }),
  add: async ({ model, data }) => {
    try {
      if (model && data?._id) {
        const objStore = await handleIDB.getIdbObjStore({ model, mode: 'readwrite' })
        const insert = objStore?.add(data, data._id)
        // insert.onsuccess = (e) => console.log(`${model} inserted: ${e.target.result}`)
        insert.onerror = (err) => console.log(`${model} insert error: ${JSON.stringify(err)}`)
      }
    } catch (err) {
      handleErr({ err })
    }
  },
  put: async ({ model, _id, data }) => {
    try {
      if (model && (_id || data?._id) && data) {
        const objStore = await handleIDB.getIdbObjStore({ model, mode: 'readwrite' })
        const update = objStore ? objStore.put(data, _id || data._id) : {}
        // update.onsuccess = (e) => console.log(`${model} updated: ${e.target.result}`)
        update.onerror = (err) => console.log(`${model} update error: ${JSON.stringify(err)}`)
      }
    } catch (error) {
      console.error(error)
    }
  },
  delete: async ({ model, _id }) => {
    try {
      const objStore = await handleIDB.getIdbObjStore({ model, mode: 'readwrite' })
      const remove = objStore.delete(_id)
      // remove.onsuccess = (e) => console.log(`${model} removed`)
      remove.onerror = (err) => console.log(`${model} remove error: ${err}`)
    } catch (err) {
      handleErr({ err })
    }
  },
  deleteAll: async ({ model }) => {
    try {
      const objStore = await handleIDB.getIdbObjStore({ model, mode: 'readwrite' })
      const removeAll = objStore?.clear()
      // removeAll.onsuccess = (e) => console.log(`${model} Clear`)
      removeAll.onerror = (err) => console.log(`${model} Clear error: ${err}`)
    } catch (err) {
      handleErr({ err })
    }
  },
  saveToLocalDB: async () => {
    try {
      if (handleIDB.localDB.dataToSave.length) {
        for (const [idx, item] of handleIDB.localDB.dataToSave.entries()) {
          const { model, _id, action, data } = item
          // action = get, getAll, add, put, delete
          await handleIDB[action]({ model, _id: _id || data._id, data })
          // handleIDB.localDB.dataToSave.splice(idx, 1)
        }
      }
    } catch (err) {
      handleErr({ err })
    }
  },
  sendOfflineTripsToServer: async ({ userInfo, dispatch }) =>
    new Promise(async (resolve) => {
      try {
        //   for (const model of handleIDB.localDB.models) {
        //   const putUrl = `${BASE_URL}/${model.toLowerCase()}s/`
        // const savedRequests = []
        // const objStore = await handleIDB.getIdbObjStore({ model: 'Trip' })
        // const req = objStore.openCursor()

        // req.onsuccess = async (e) => {
        // const cursor = e.target.result
        const trips = (await handleIDB.getAll({ model: 'Trip' })) || []
        // console.log(trips.some((t) => !userIsTripDriver({ trip: t, id: userInfo?._id })))
        if (trips.some((t) => !userIsTripDriver({ trip: t, id: userInfo?._id }))) {
          // if (trips.some((t) => t?.driver && t?.driver !== userInfo?._id)) {
          return resolve('UserIsNotDriver')
        }
        if (!trips.length) return resolve('NOTRIPS')

        const putUrl = `${BASE_URL}/trips/`
        // if (cursor && cursor.value._id) {
        // Keep moving the cursor forward and collecting saved requests.
        // savedRequests.push(cursor.value)
        // cursor.continue()
        // } else {

        // const token = await handleIDB.getVal({
        //   model: 'Auth',
        //   _id: 'XPERDRIVER_TOKEN',
        // })
        // At this point, we have collected all the post requests in indexedb.
        for (const t of trips) {
          const { _id } = t || {}
          if (_id) {
            // send them to the server one after the other

            dispatch(
              updateTripAction({
                userInfo: uInfo(userInfo),
                id: _id,
                formData: {
                  ...t,
                  offLine: true,
                  // doNotNotifyDriver: doNotNotifyDriverRef.current,
                },
                cb: async () => {
                  await handleIDB.delete({ model: 'Trip', _id })
                  caches.open(cacheName).then((cache) => {
                    // cache.delete(response.url)
                    cache.delete(putUrl + _id)
                    return resolve('UPDATED')
                  })
                },
              }),
            )

            // return await fetch(putUrl + _id, {
            //   method: 'PATCH',
            //   headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json',
            //     Authorization: `Bearer ${token}`,
            //   }, // if you have any other headers put them here,
            //   //   method: model === 'Destination' ? 'PUT' : 'PATCH',
            //   body: JSON.stringify({ ...item, offLine: true }),
            //   // body: JSON.stringify({ ...savedRequest, offLine: true }),
            //   // mode: 'no-cors',
            // })
            //   .then(async (response) => {
            //     // console.log('[sw] Saved Request', item)
            //     // console.log('[sw] Server Response', response)
            //     if (response.status < 400) {
            //       // If sending the POST request was successful, then remove it from the IndexedDB.
            //       //   getIdbObjStore(FOLDER_NAME, 'readwrite').clear()
            //       await handleIDB.delete({ model: 'Trip', _id })
            //       //   handleIDB.delete({ model, _id: savedRequest.id })
            //       return caches.open(cacheName).then((cache) => {
            //         cache.delete(response.url)
            //         resolve('OK')
            //       })
            //     }
            //   })
            //   .catch((err) => {
            //     // This will be triggered if the network is still down. The request will be replayed again
            //     // the next time the service worker starts up.
            //     console.error('Send to Server failed:', err)
            //     // since we are in a catch, it is important an error is thrown,
            //     // so the background sync knows to keep retrying sendto server
            //     resolve('ERROR')
            //     return err
            //   })
          } else {
            return resolve('NOID')
          }
        }
      } catch (error) {
        return resolve('ERROR')
      }
    }),
}

export default handleIDB
