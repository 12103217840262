export const defaultBrokerPricing = {
  delivery: 15,
  deliveryExtramile: 1.5,
  standard: 25,
  standardExtramile: 1.5,
  wheelchair: 45,
  wheelchairExtramile: 1.5,
  bariatricWheelchair: 65,
  bariatricWheelchairExtramile: 1.5,
  stretcher: 150,
  stretcherExtramile: 2,
  bariatricStretcher: 170,
  bariatricStretcherExtramile: 2,
  milesIncluded: 10,
}
