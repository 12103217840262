import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import './scss/style.scss'
import getSocket from 'src/services/socket'
import { companyLogout } from 'src/actions/companyActions'
import ScrollToTop from './components/ScrollToTop'

import {
  CREATE_DESTINATIONS_SUCCESS,
  CREATE_PRODUCT_SUCCESS,
  CREATE_TRIP_SUCCESS,
  CREATE_HOLIDAY_FAIL,
  CREATE_HOLIDAY_SUCCESS,
  CREATE_INSPECTION_SUCCESS,
  CREATE_ABSENSE_FAIL,
  CREATE_ABSENSE_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_VEHICLE_SUCCESS,
  DELETE_DESTINATION_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_VEHICLE_SUCCESS,
  GET_COMPANY_SETTINGS_SUCCESS,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_TRIP_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_INSPECTION_SUCCESS,
  UPDATE_ABSENSE_FAIL,
  UPDATE_ABSENSE_SUCCESS,
  UPDATE_VEHICLE_SUCCESS,
  DELETE_SCHEDULED_TRIP_SUCCESS,
  DELETE_TRIP_SUCCESS,
  UPDATE_SIGNATURE_SUCCESS,
  CREATE_INSURANCE_SUCCESS,
  UPDATE_INSURANCE_SUCCESS,
  DELETE_INSURANCE_SUCCESS,
  CREATE_REMINDER_SUCCESS,
  UPDATE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  CREATE_BROKER_SUCCESS,
  UPDATE_BROKER_SUCCESS,
  DELETE_BROKER_SUCCESS,
  CREATE_CLOCK_SUCCESS,
  CREATE_CLOCK_FAIL,
  UPDATE_CLOCK_SUCCESS,
  UPDATE_CLOCK_FAIL,
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_FAIL,
  UPDATE_BROKER_PRICING_SUCCESS,
} from './constants'
import {
  getCompanyByDomain,
  getCompanySettings,
  getUsersAction,
  getTripsAction,
} from './actions/adminActions'
import {
  formatDate,
  formatToHTMLinput,
  isInTimeRange,
  isLocTimeOff,
  isDelay,
  timeDifference,
  daysScheduled,
} from './utils/handle-date'
import { updateSearchTermsAction } from './actions/userActions'
import getBase64Url from './utils/base64url'
import geoLocation from './utils/geoLocation'
import { updateUserLastLocation } from './actions/adminActions'
import {
  areEquals,
  getFullName,
  isFound,
  isSameCustomerAndPk,
  isScheduled,
  latestStartStopTime,
  someFacility,
  userIsTripDriver,
  validCoords,
} from './utils/helpers'
import notify from './utils/notify'
import PlaySound from './components/PlaySound'
import isEmpty from './utils/is-empty'
import handleIDB from './utils/handle-indexed-db'
import mobileOS from './utils/iOS'
import { isAdmin, isCustomer, isDriver, isSuperadmin } from './utils/check-user-level'
import handleErr from './utils/handle-error'
import FullLoader from './components/FullLoader'
import { initiaSearchTerms } from './utils/initiaSearchTerms'
import WidgetContainer from './views/widget/WidgetContainer'
// import Setnewpassword from './views/pages/setnewpassword/Setnewpassword'
// import { WatchLocation } from './utils/watchLocation'
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// import { WatchLocation } from './utils/watchLocation'
// import getBase64Url from './utils/base64url'
// import { useDispatch } from 'react-redux'
// import { COMPANY_LOGIN_SUCCESS } from './constants'

// Containers

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Setnewpassword = React.lazy(() => import('./views/pages/setnewpassword/Setnewpassword'))
const Sendcode = React.lazy(() => import('./views/pages/sendcode/Sendcode'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const dispatch = useDispatch()

  const { company, usersList, tripsList, vehiclesList } = useSelector((state) => state.admin)
  const { userInfo, companyInfo } = useSelector((state) => state?.companyAuth)
  const { searchTerms } = useSelector((state) => state?.user)

  const [showFullLoader, setShowFullLoader] = useState(null)
  const [playSound, setPlaySound] = useState(null)
  const [socket, setSocket] = useState()

  const searchTermsRef = useRef(searchTerms)
  const userInfoRef = useRef(userInfo)
  // const userDataRef = useRef()
  const tripsDataRef = useRef(tripsList?.data)
  const vehiclesDataRef = useRef(vehiclesList?.data)
  const usersDataRef = useRef(usersList?.data)
  const userDataRef = useRef()
  const updateLocFrec = useRef(66606)
  const locationIntervalRef = useRef(null)
  const lastRefresh = useRef(new Date())
  const lastConexAttempt = useRef(new Date())
  const reconnectData = useRef(null)
  const conexAttempts = useRef(1)

  const toggleSound = ({ fail }) => {
    try {
      setPlaySound(() => <PlaySound fail={fail} />)
      setTimeout(() => setPlaySound(null), 4000)
    } catch (err) {
      handleErr({ err })
    }
  }

  const getDataFromServer = ({ onConnected }) => {
    try {
      setShowFullLoader(
        <FullLoader
          loadingMsg={'Updating...'}
          // progressBar={progressBarRef.current}
          cb={() => ({ func: () => setShowFullLoader(null) })}
        />,
      )
      // setTimeout(() => {
      const { level, _id } = userInfo || {}
      if (_id) {
        // if (_id && !isCustomer({ level })) {
        const { driver, tripState, customer } = searchTermsRef.current
        const payload = {
          ...searchTermsRef.current,
          // constraint: isDriver({ level }) ? todayRange() : constraint,
          // tripState: isDriver({ level }) ? 'pending' : tripState || 'pending',
          tripState: isDriver({ level }) || !tripState ? 'pending' : tripState,
          driver: isDriver({ level }) ? _id : driver,
          customer: isCustomer({ level }) ? _id : customer || null,
        }
        dispatch(updateSearchTermsAction(payload))
        const cb = () => {
          // if (window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          if (isEmpty(userDataRef.current)) {
            dispatch(
              getUsersAction({
                userById: _id,
                cb: ({ users }) => {
                  setShowFullLoader(null)
                  const updateUserInfo = users?.find((u) => u?._id === userInfo?._id)
                  if (updateUserInfo) {
                    handleIDB?.put({ model: 'Auth', _id: 'userData', data: updateUserInfo })
                  }
                  // dispatch(
                  //   getDestinationsAction({
                  //     cb: () => {
                  // dispatch(
                  //   getVehiclesAction({
                  //     cb: ({ vehicles }) => {
                  if (userInfo?.company?._id && !searchTerms.companySettingsPulled) {
                    // not superadmin
                    // dispatch(getQuickNotes({ authorId: userInfo?._id }))
                    dispatch(
                      getCompanySettings({
                        id: userInfo?.company?._id,
                        cb: () => {
                          dispatch(updateSearchTermsAction({ companySettingsPulled: true }))
                        },
                        // cb: (data) => {
                        //   if (
                        //     !isSuperadmin(userInfo) &&
                        //     data?.companySettings?.isDeactivated
                        //   ) {
                        //     dispatch(companyLogout())
                        //   }
                        // },
                      }),
                    )
                  }

                  // if (isDriver({ level })) {
                  //   const vehicle = isFound({
                  //     list: vehicles,
                  //     id: userInfo?._id,
                  //     byKey: 'driver',
                  //   })
                  //   vehicle &&
                  //     dispatch(
                  //       getInspection({
                  //         // yesterdayConstraint: yesterdayRange(),
                  //         latest: true,
                  //         constraint: todayRange(),
                  //         vehicle: vehicle?._id,
                  //       }),
                  //     )
                  // }
                  //     },
                  //   }),
                  // )
                  //     },
                  //   }),
                  // )
                },
              }),
            )
          } else {
            setShowFullLoader(null)
          }
        }

        if (onConnected && window.location.pathname === '/') {
          // if (onConnected || window.location.pathname === '/') {
          // console.log('getTripsAction')
          dispatch(
            getTripsAction({
              ...payload,
              cb,
              // cb: () => {
              //   // if (window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
              //   dispatch(
              //     getUsersAction({
              //       userById: _id,
              //       cb: ({ users }) => {
              //         const updateUserInfo = users?.find((u) => u?._id === userInfo?._id)
              //         if (updateUserInfo) {
              //           handleIDB?.put({ model: 'Auth', _id: 'userData', data: updateUserInfo })
              //         }
              //         // dispatch(
              //         //   getDestinationsAction({
              //         //     cb: () => {
              //         dispatch(
              //           getVehiclesAction({
              //             cb: ({ vehicles }) => {
              //               if (userInfo?.company?._id) {
              //                 // not superadmin
              //                 // dispatch(getQuickNotes({ authorId: userInfo?._id }))
              //                 dispatch(
              //                   getCompanySettings({
              //                     id: userInfo?.company?._id,
              //                     // cb: (data) => {
              //                     //   if (
              //                     //     !isSuperadmin(userInfo) &&
              //                     //     data?.companySettings?.isDeactivated
              //                     //   ) {
              //                     //     dispatch(companyLogout())
              //                     //   }
              //                     // },
              //                   }),
              //                 )
              //               }

              //               if (isDriver({ level }) && vehicles?.length && vehicles[0]._id) {
              //                 dispatch(
              //                   getInspection({
              //                     // yesterdayConstraint: yesterdayRange(),
              //                     latest: true,
              //                     constraint: todayRange(),
              //                     vehicle: vehicles[0]._id,
              //                   }),
              //                 )
              //               }
              //             },
              //           }),
              //         )
              //         //     },
              //         //   }),
              //         // )
              //       },
              //     }),
              //   )
              // },
            }),
          )
        } else {
          cb()
          // dispatch(
          //   getUsersAction({
          //     userById: _id,
          //     cb: ({ users }) => {
          //       const updateUserInfo = users?.find((u) => u?._id === userInfo?._id)
          //       if (updateUserInfo) {
          //         handleIDB?.put({ model: 'Auth', _id: 'userData', data: updateUserInfo })
          //       }
          //       // dispatch(
          //       //   getDestinationsAction({
          //       //     cb: () => {
          //       dispatch(
          //         getVehiclesAction({
          //           cb: ({ vehicles }) => {
          //             if (userInfo?.company?._id) {
          //               //not superadmin
          //               // dispatch(getQuickNotes({ authorId: userInfo?._id }))
          //               dispatch(
          //                 getCompanySettings({
          //                   id: userInfo?.company?._id,
          //                   // cb: (data) => {
          //                   //   if (
          //                   //     !isSuperadmin(userInfo) &&
          //                   //     data?.companySettings?.isDeactivated
          //                   //   ) {
          //                   //     dispatch(companyLogout())
          //                   //   }
          //                   // },
          //                 }),
          //               )
          //             }

          //             if (isDriver({ level }) && vehicles?.length && vehicles[0]._id) {
          //               dispatch(
          //                 getInspection({
          //                   // yesterdayConstraint: yesterdayRange(),
          //                   latest: true,
          //                   constraint: todayRange(),
          //                   vehicle: vehicles[0]._id,
          //                 }),
          //               )
          //             }
          //           },
          //         }),
          //         // )
          //         //   },
          //         // }),
          //       )
          //     },
          //   }),
          // )
        }
      } else {
        setShowFullLoader(null)
      }
    } catch (err) {
      clearInterval(locationIntervalRef.current)
      setShowFullLoader(null)
      handleErr({ err })
    }
  }

  const connectSocket = ({ force }) => {
    try {
      if (
        userInfoRef.current?._id &&
        socket?.connect &&
        !socket?.connected &&
        (navigator?.onLine || force)
      ) {
        // socket.close()
        socket?.connect()
      } else if (!userInfoRef.current?._id && socket?.connected) {
        socket?.disconnect()
      }
      if (userInfoRef.current?._id && !navigator?.onLine) getDataFromServer({ onConnected: false }) // data fallback from IDB
    } catch (err) {
      clearInterval(locationIntervalRef.current)
      handleErr({ err })
    }
  }

  const handleOnlineEvent = () => {
    try {
      // if (isDriver(userInfo)) {
      //   handleIDB.sendOfflineTripsToServer({ userInfo, dispatch }).then((res) => {
      //     connectSocket()
      //   })
      // } else {
      connectSocket({})
      // }
    } catch (err) {
      connectSocket({})
      handleErr({ err })
    }
  }

  const handleOfflineEvent = () => {
    try {
      connectSocket({ force: true })
    } catch (err) {
      connectSocket({ force: true })
      handleErr({ err })
    }
  }

  const wakeLockVisibilityChange = (wakeLock, requestWakeLock) => {
    if (wakeLock !== null && document.visibilityState === 'visible') {
      wakeLock = requestWakeLock()
    }
  }

  useEffect(() => {
    // const makeWakeReq = async () => {
    //   wakeLock.request = await requestWakeLock()
    // }
    // makeWakeReq()
    // const requestWakeLock = async () => {
    // Create a reference for the Wake Lock.

    try {
      setSocket(getSocket)

      if ('WakeLock' in window && 'request' in window.WakeLock) {
        let wakeLock = null
        const requestWakeLock = () => {
          try {
            const controller = new AbortController()
            const signal = controller.signal
            window.WakeLock.request('screen', { signal }).catch((e) => {
              if (e?.name === 'AbortError') {
                console.log('Wake Lock was aborted')
              } else {
                handleErr({ err: e })
              }
            })
            console.log('Wake Lock is active')
            return controller
          } catch (err) {
            handleErr({ err })
          }
        }
        wakeLock = requestWakeLock()
        document.addEventListener('visibilitychange', () => {
          wakeLockVisibilityChange(wakeLock, requestWakeLock)
        })
      } else if ('wakeLock' in navigator && 'request' in navigator.wakeLock) {
        let wakeLock = null
        const requestWakeLock = async () => {
          try {
            wakeLock = await navigator.wakeLock.request('screen')
            wakeLock.addEventListener('release', (e) => {
              // console.log(e)
              console.log('Wake Lock was released')
            })
            console.log('Wake Lock is active')
            return wakeLock
          } catch (err) {
            console.error(`${err?.name}, ${err?.message}`)
            handleErr({ err })
          }
        }
        wakeLock = requestWakeLock()
        document.addEventListener('visibilitychange', () => {
          wakeLockVisibilityChange(wakeLock, requestWakeLock)
        })
      } else {
        handleErr({
          err: {
            message: 'Wake Lock API not supported.',
          },
        })
      }
    } catch (err) {
      handleErr({ err })
    }

    // try {
    //   if ('wakeLock' in navigator) {
    //     //Wake Lock is active!'
    //     wakeLock.request = await navigator.wakeLock.request('screen')
    //     // .then((wakeLock) => {
    //     // statusElem.textContent = 'Wake Lock is active!'
    //     console.log('Wake Lock is active!')

    //     wakeLock.request.addEventListener('release', () => {
    //       // the wake lock has been released
    //       // statusElem.textContent = 'Wake Lock has been released';
    //       console.log('Screen Wake Lock released:', wakeLock.request)
    //     })

    //     // window.addEventListener('visibilitychange', async (e) => {
    //     //   try {
    //     //     e.preventDefault()
    //     //     if (wakeLock !== null && document.visibilityState === 'visible') {
    //     //       wakeLock.request = await navigator.wakeLock.request('screen')
    //     //       console.log('Wake Lock is active!')
    //     //     }
    //     //   } catch (err) {
    //     //     handleErr({ err })
    //     //   }
    //     // })
    //     // wakeLock.release().then(() => {
    //     //   wakeLock = null
    //     // })
    //   } else {
    //     handleErr({
    //       err: {
    //         message: 'Wake lock is not supported by this browser.',
    //       },
    //     })
    //   }
    // } catch (err) {
    //   // The Wake Lock request has failed - usually system related, such as battery.
    //   // statusElem.textContent = `${err.name}, ${err.message}`
    //   console.log(`${err.name}, ${err.message}`)
    //   handleErr({ err })
    // }
    // }
  }, [])

  useEffect(() => {
    tripsDataRef.current = tripsList?.data
  }, [tripsList])

  useEffect(() => {
    vehiclesDataRef.current = vehiclesList?.data
    // console.log('vehiclesList', vehiclesList?.data)
    if (vehiclesList.success) refresDriverLoc()
  }, [vehiclesList])

  useEffect(() => {
    searchTermsRef.current = searchTerms
  }, [searchTerms])

  const updateLoc = async () => {
    try {
      // if (isDriver(userInfo)) {
      if (userDataRef.current?._id && isDriver(userDataRef.current)) {
        const updatedLocationCoords = await geoLocation()
        const driverLastLocation = userDataRef.current?.lastLocation?.location || {
          lat: 0,
          lng: 0,
        }
        const timeDiff = timeDifference({
          startTime: new Date(),
          stopTime: new Date(userDataRef.current?.lastLocation?.date || new Date()),
        })
        const lastRefreshTimeDiff = timeDifference({
          startTime: new Date(),
          stopTime: new Date(lastRefresh.current || new Date()),
        })
        // console.log(isLocTimeOff(timeDiff), timeDiff)
        console.log('timeDiff: ', timeDiff)
        console.log('isLocTimeOff: ', isLocTimeOff(timeDiff))
        console.log('lastRefreshTimeDiff: ', lastRefreshTimeDiff)
        const isLastRefreshLimit = isDelay({ limit: 7, timeDiff: lastRefreshTimeDiff })
        console.log('isDelay: ', isLastRefreshLimit)
        // console.log('!areEquals: ', !areEquals(driverLastLocation, updatedLocationCoords))
        console.log('driverLastLocation: ', driverLastLocation)
        console.log('driverUpdatedLoc  : ', updatedLocationCoords)
        console.log('isLocTimeOff(timeDiff)  : ', isLocTimeOff(timeDiff))
        console.log('=================')
        if (
          validCoords({ coords: updatedLocationCoords }) &&
          ((isLastRefreshLimit &&
            (Number(updatedLocationCoords?.lat) !== Number(driverLastLocation?.lat) ||
              Number(updatedLocationCoords?.lng) !== Number(driverLastLocation?.lng))) ||
            isLocTimeOff(timeDiff))
        ) {
          console.log('updateUserLastLocation')
          lastRefresh.current = new Date()
          dispatch(
            updateUserLastLocation({
              updatedLocationCoords,
              _id: userDataRef.current?._id,
              company: userDataRef.current?.company,
            }),
          )
        }
      }
    } catch (err) {
      clearInterval(locationIntervalRef.current)
      handleErr({ err })
    }
  }

  const refresDriverLoc = () => {
    clearInterval(locationIntervalRef.current)
    if (
      userDataRef.current?._id &&
      isDriver(userDataRef.current) &&
      vehiclesDataRef?.current?.length
    ) {
      const vehicle = vehiclesDataRef?.current?.find((v) => v?.driver === userDataRef.current?._id)
      if (!vehicle?.active) {
        updateLoc()
        locationIntervalRef.current = setInterval(updateLoc, updateLocFrec.current)
      }
    }
  }

  useEffect(() => {
    try {
      if (usersList?.success && !areEquals(usersDataRef.current, usersList.data)) {
        usersDataRef.current = usersList?.data
        userDataRef.current = isFound({ list: usersList?.data, id: userInfo?._id })
        if (userDataRef?.current?.isDeactivated) dispatch(companyLogout())
      }
    } catch (err) {
      handleErr({ err })
    }
  }, [usersList.data, userInfo, dispatch, usersList?.success])

  useEffect(() => {
    try {
      const loadAppData = async () => {
        try {
          const companyName = companyInfo?.name || company?.data?.name || 'XPERDRIVER'
          const companyLogo =
            companyInfo?.logoSrc || company?.data?.logoSrc || '/xperdriverlogo.png'
          // const urlProtocol = window.location.protocol + '//'
          const companyDomain = companyInfo?.domain || company?.data?.domain || 'xperdriver.com'
          // const companyUrl = urlProtocol + companyDomain
          // if (
          //   (companyInfo?.domain || company.data?.domain) &&
          //   window.location.hostname !== companyDomain
          // ) {
          //   // if (window.location.hostname !== 'localhost' && window.location.hostname !== companyDomain) {
          //   // window.location.href = companyUrl
          //   // window.location.href = `${urlProtocol}xperdriver.com` // companyUrl
          // }
          document.title = `${companyName} - Transportation App`
          const siteDescription = document.querySelector('[name="description"]')?.content
          document.querySelector('[name="description"]').content =
            siteDescription?.indexOf(companyName) === -1
              ? `${companyName} ${siteDescription}`
              : siteDescription

          if (siteDescription?.indexOf(companyName) === -1) {
            document.querySelector('[rel="icon"]').href = companyLogo
            document.querySelector('[rel="apple-touch-icon"]').href = companyLogo
            document.querySelector('[rel="canonical"]').href = `https://${companyDomain}`
            // document.querySelector('[rel="apple-touch-icon"]').href =
            // (await getBase64Url({ imageBase64Src: companyLogo, size: 120 })) ||
            // 'assets/icons/icon-144x144.png'
            // window.onload = async () => {

            const dynamicManifest = {
              // $schema: 'https://json.schemastore.org/web-manifest-combined.json',
              id: userInfo?._id || '/',
              name: companyName,
              short_name: companyName,
              description: `${companyName} Transportation App`,
              // display_override: ['minimal-ui'],
              display: 'standalone',
              // start_url: `/`,
              // start_url: `/?source=pwa`,
              // start_url: window.location.origin,
              start_url: mobileOS() === `Android` ? `/` : window.location.origin, // window.location.origin breaks on android
              // start_url: mobileOS() === `iOS` ? window.location.origin : `/`, // window.location.origin breaks on android
              categories: ['navigation'],
              orientation: 'any',
              theme_color: '#1976d2',
              background_color: '#1976d2',
              icons: [
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 72 })) ||
                    'assets/icons/icon-72x72.png',
                  sizes: '72x72',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 96 })) ||
                    'assets/icons/icon-96x96.png',
                  sizes: '96x96',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 128 })) ||
                    'assets/icons/icon-128x128.png',
                  sizes: '128x128',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 144 })) ||
                    'assets/icons/icon-144x144.png',
                  sizes: '144x144',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 152 })) ||
                    'assets/icons/icon-152x152.png',
                  sizes: '152x152',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 192 })) ||
                    'assets/icons/icon-192x192.png',
                  sizes: '192x192',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 384 })) ||
                    'assets/icons/icon-384x384.png',
                  sizes: '384x384',
                  type: 'image/png',
                  purpose: 'any',
                },
                {
                  src:
                    (await getBase64Url({ imageBase64Src: companyLogo, size: 512 })) ||
                    'assets/icons/icon-512x512.png',
                  sizes: '512x512',
                  type: 'image/png',
                  purpose: 'any',
                },
              ],
            }
            const stringManifest = JSON.stringify(dynamicManifest)
            // const manifestFile = new File([stringManifest], 'manifest.json', {
            //   type: 'application/json',
            // })
            const manifestFile = new Blob([stringManifest], { type: 'application/json' })
            manifestFile.name = 'manifest.json'
            const manifestURL = URL.createObjectURL(manifestFile, 'manifest.json')

            // const manifestURL = URL.createObjectURL(manifestFile)
            document.querySelector('[rel="manifest"]')?.remove() //.href = manifestURL
            const manifestEl = document.createElement('link')
            manifestEl.rel = 'manifest'
            // manifestEl.href = 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest)
            // if (!document.querySelector('[rel="manifest"]'))
            // document.getElementsByTagName('head')[0].appendChild(manifestEl)
            manifestEl.href = manifestURL
            document.head.appendChild(manifestEl)

            // const manifestString = JSON.stringify({
            //   ...manifest,
            //   start_url: `${homePagePath}${storeCode}`,
            // });
            // manifestElement?.setAttribute(
            //   'href',
            //   'data:application/json;charset=utf-8,' + encodeURIComponent(manifestString),
            // )

            // document
            //   .querySelector('[rel="manifest"]')
            //   .setAttribute(
            //     'href',
            //     'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest),
            //   )
          }
        } catch (err) {
          handleErr({ err })
        }
      }
      if (
        !isEmpty(companyInfo) ||
        (isEmpty(companyInfo) && !isEmpty(company))
        // &&
        // !isEmpty(company)
        //  &&
        // !isEmpty(userInfo) &&
        // usersList?.data?.length
      ) {
        loadAppData()
      }

      if (isEmpty(companyInfo) && !searchTermsRef?.current?.getCompanyByDomainPulled) {
        const urlParts = window.location.hostname.split('.')
        const domain = urlParts?.filter((part) => part !== 'www')?.join('.')
        dispatch(getCompanyByDomain({ domain }))
        dispatch(updateSearchTermsAction({ getCompanyByDomainPulled: true }))
      }
      if (!isSuperadmin(userInfo) && companyInfo?._id && !companyInfo?.active) {
        dispatch(companyLogout())
      }
    } catch (err) {
      handleErr({ err })
    }
  }, [companyInfo, company, userInfo, dispatch, searchTerms])

  const handleVisibility = async (e) => {
    try {
      e?.preventDefault && e.preventDefault()
      clearInterval(locationIntervalRef.current)
      const pageIsVisible = document.visibilityState === 'visible'
      if (userDataRef.current?._id && (pageIsVisible || socket?.connected)) {
        console.log('handleVisibility')
        connectSocket({})
        refresDriverLoc()
        // dispatch(
        //   sendEmailAction({
        //     noAlert: true,
        //     to: 'dev@xperdriver.com',
        //     subject: `pageIsVisible: ${pageIsVisible}`,
        //     bodyContent: `user: ${userDataRef?.current?.firstName} ${userDataRef?.current?.lastName}`,
        //   }),
        // )
      }
    } catch (err) {
      clearInterval(locationIntervalRef.current)
      handleErr({ err })
    }
  }

  const joinRoom = ({ socket, userInfo }) => {
    try {
      if (userInfo?._id) {
        const id = userInfo.company?._id || userInfo._id // if user is superadmin
        console.log(`Joining Room...`)
        socket?.emit('joinRoom', {
          userId: userInfo._id,
          companyId: id,
        })
      } else {
        socket?.disconnect()
      }
    } catch (err) {
      handleErr({ err })
    }
  }

  const handleReconnect = ({ doNotReconnect, data }) => {
    // console.log(data)
    reconnectData.current = {
      ...data,
      err: `${getFullName({ user: userDataRef.current })} ${mobileOS()}  ${data.errorMsg} ${
        data.onFunc
      }`,
    }
    if (!doNotReconnect && isDelay({ limit: 5, lastUpdate: lastConexAttempt.current })) {
      lastConexAttempt.current = new Date()
      clearInterval(locationIntervalRef.current)
      setShowFullLoader(
        <FullLoader
          loadingMsg={`Connecting... ${
            conexAttempts.current > 1 ? `${conexAttempts.current} Attempts` : ''
          }`}
          // progressBar={progressBarRef.current}
          cb={() => ({
            func: () => {
              connectSocket({ force: true })
              setShowFullLoader(null)
            },
          })}
        />,
      )
      console.log('reconnecting...')
      !doNotReconnect && connectSocket({ force: true })
      conexAttempts.current++
    }
  }

  useEffect(() => {
    try {
      userInfoRef.current = userInfo
      connectSocket({})
      window.addEventListener('online', handleOnlineEvent)
      window.addEventListener('offline', handleOfflineEvent)

      if (socket?.connected && userInfo?._id) {
        if (userInfo?._id) {
          window.addEventListener('visibilitychange', handleVisibility, false)
          joinRoom({ socket, userInfo })
          getDataFromServer({ onConnected: false })
        }
      }

      // socket?.on('reconnect_attempt', (attempt) => {
      //   try {
      //     console.log('re-connecting... ', attempt)
      //     setShowFullLoader(
      //       <FullLoader
      //         loadingMsg={`Connecting... Attempt:${attempt}`}
      //         // progressBar={progressBarRef.current}
      //         cb={() => ({ func: () => setShowFullLoader(null) })}
      //       />,
      //     )
      //   } catch (err) {
      //     handleErr({ err })
      //   }
      // })

      if (userInfo?._id) {
        socket?.on('connect', async () => {
          try {
            console.log('connected ')
            conexAttempts.current = 1
            setShowFullLoader(null)
            joinRoom({ socket, userInfo })

            if (!isEmpty(reconnectData.current)) {
              const { err } = reconnectData.current
              handleErr({
                dispatch, // send email
                err,
                cb: () => {
                  reconnectData.current = null
                },
              })
            }
            // searchTermsRef.current = {
            //   ...searchTermsRef.current,
            //   allDestsPulled: false,
            //   allUsersPulled: false,
            //   allFacilitiesPulled: false,
            //   ...Object.fromEntries(userLevels.map((l) => [`all${l}Pulled`, false])),
            //   // {...userLevels.map(l => ({ [`all${l}Pulled`]: false }))}
            // }

            searchTermsRef.current =
              (await handleIDB.getVal({
                model: 'Searchterms',
                _id: 'searchTerms',
              })) || initiaSearchTerms
            if (userInfo?._id) {
              getDataFromServer({ onConnected: true })
              dispatch(updateSearchTermsAction(searchTermsRef.current))
              refresDriverLoc()
            }
            console.log('on.connect')
          } catch (err) {
            handleErr({ err })
          }
        })

        // socket?.on('ping', (latency) => {
        //   console.log('ping', latency)
        // })

        // socket?.on('pong', (latency) => {
        //   console.log('pong', latency)
        // })

        socket?.on('connect_error', (err) => {
          // console.log(`connect_error due to ${err?.message}`)
          handleReconnect({
            data: {
              onFunc: 'connect_error',
              errorMsg: err.message,
            },
          })
          if (socket.active) {
            // temporary failure, the socket will automatically try to reconnect
          } else {
            // the connection was denied by the server
            // in that case, `socket.connect()` must be manually called in order to reconnect
            console.log(`the connection was denied by the server due to: `, err.message)
          }
          // handleErr({ err })
        })

        socket?.on('connect_timeout', (timeout) => {
          // console.log('connect_timeout: ', timeout)
          handleReconnect({
            data: {
              onFunc: 'connect_timeout',
              errorMsg: `Timeout: ${timeout}`,
            },
          })
        })

        socket?.on('disconnect', (reason, details) => {
          // console.log('disconnected')
          // console.log('reason: ', reason)
          // console.log('details: ', details)
          handleReconnect({
            doNotReconnect: true,
            data: {
              onFunc: 'disconnect',
              errorMsg: `Reason: ${reason}, Details: ${JSON.stringify(details)}`,
            },
          })
        })

        const id = userInfo.company?._id || userInfo._id // if user is superadmin

        socket?.on('roomJoined', (data) => console.log(data))

        // console.log(id)
        socket?.on(`tripsList${id}`, (serverData) => {
          try {
            if (id) {
              const { trip, operationType } = serverData || {}
              console.log(serverData)
              const isInFacility = someFacility({
                facility: trip?.facility,
                user: userDataRef.current,
              })
              // const someFacility =
              //   (!trip?.facility?.length && !userDataRef.current?.facility?.length) ||
              //   (trip?.facility?.length &&
              //     trip?.facility?.some((tFacility) =>
              //       userDataRef.current?.facility?.some((uFacility) => uFacility === tFacility),
              //     )) ||
              //   // !userDataRef.current?.facility?.length ||
              //   // userDataRef.current?.facility.some((uFacility) =>
              //   //   trip?.facility?.some((tFacility) => tFacility === uFacility),
              //   // ) ||
              //   // isSupervisor(userDataRef.current) ||
              //   isSuperadmin(userDataRef.current)

              if (
                trip &&
                trip?.company === userInfo?.company?._id &&
                (!isCustomer(userInfo) ||
                  (isCustomer(userInfo) && trip?.customer === userInfo?._id)) &&
                !isEmpty(trip?.times) &&
                isInFacility &&
                (userIsTripDriver({ trip, id: userInfo?._id }) ||
                  (!isDriver(userInfo) &&
                    (!searchTermsRef.current.driver ||
                      (searchTermsRef.current.driver &&
                        trip.driver &&
                        searchTermsRef.current.driver === trip.driver) ||
                      (!trip.driver && !searchTermsRef.current.driver) ||
                      (!trip.driverHistory?.length && !searchTermsRef.current.driver))))
              ) {
                // const { timeIdx } = tripInfo({ trip })
                const startTime = Date.parse(
                  formatToHTMLinput({
                    dateData: new Date(
                      latestStartStopTime({ trip }),
                      // trip?.times[`expectedStartTime${timeIdx}`] ||
                      //   trip?.times[`expectedPickupTime${timeIdx}`] ||
                      //   trip?.times[`expectedStartTime${timeIdx - 1}`] ||
                      //   trip?.times[`expectedPickupTime${timeIdx - 1}`],
                    ),
                  }),
                )
                const { constraint } = searchTermsRef.current
                if (
                  !trip?.customer ||
                  // (!trip?.driver && searchTermsRef.current?.driver) ||
                  (isScheduled(trip) && !daysScheduled({ trip, constraint })?.length) ||
                  (!isScheduled(trip) && !isInTimeRange({ startTime, constraint }))
                ) {
                  // debugger
                  dispatch({ type: DELETE_SCHEDULED_TRIP_SUCCESS, payload: trip })
                  dispatch({ type: DELETE_TRIP_SUCCESS, payload: trip })
                } else if (
                  (!isScheduled(trip) && isInTimeRange({ startTime, constraint })) ||
                  (isScheduled(trip) && daysScheduled({ trip, constraint })?.length)
                ) {
                  // debugger
                  try {
                    // setTimeout(() => {
                    const foundTrip = tripsDataRef.current?.find((t) => t?._id === trip?._id)
                    const newDriver =
                      !isDriver(userInfo) || !foundTrip
                        ? ''
                        : foundTrip?.driver !== trip?.driver
                        ? trip?.driver
                        : ''
                    const isCustomerReady =
                      (userIsTripDriver({ trip, id: userInfo?._id }) || !isDriver(userInfo)) &&
                      foundTrip &&
                      !foundTrip?.customerReady &&
                      trip?.customerReady
                        ? true
                        : false

                    const isNewNote =
                      foundTrip &&
                      foundTrip?.notes?.every(
                        (n) => !trip?.notes?.find((tn) => n?._id === tn?._id),
                      ) &&
                      (userIsTripDriver({ trip, id: userInfo?._id }) || !isDriver(userInfo))
                        ? trip?.notes[trip?.notes?.length - 1]
                        : ''
                    const customer =
                      usersDataRef?.current.find((u) => u?._id === trip?.customer) || {}
                    const custFullName = getFullName({ user: customer })

                    if (operationType === 'insert') {
                      if (userIsTripDriver({ trip, id: userInfo?._id })) {
                        if (newDriver && userIsTripDriver({ trip, id: userInfo?._id })) {
                          notify({
                            msg: `New Trip ${
                              custFullName ? `for ${custFullName}` : ''
                            } at ${formatDate({
                              dateData: new Date(trip?.times[`expectedPickupTime1`] || Date.now),
                            })}`,
                            toastId: 'newtrip', // trip?.customer,
                            type: 'success',
                            toggleSound,
                            autoClose: 180000,
                          })
                        }
                      }
                      // } else if (operationType === 'delete' || operationType === 'update') {
                      //   // const deletedTrip = tripsDataRef.current?.find(
                      //   //   (trip) => trip._id === ._id,
                      //   // )
                      //   const remainigTrips = tripsDataRef.current?.filter((t) => t?._id !== trip?._id)
                      //   if (remainigTrips.length > tripsDataRef.current.length) {
                      //     // toggleModal({ modalVisible: false })
                      //     // globalQ.current.firstOnQueue = ''
                      //     // globalQ.current.tripsQ = []
                      //     // filterAndSortTrips({ trips: remainigTrips })
                      //     if (userIsTripDriver({ driver: trip?.driver })) {
                      //       notify({
                      //         msg: `1 Trip Deleted - ${formatDate({})}`,
                      //         type: 'error',
                      //         toastId: 'deletetrip',
                      //         fail: true,
                      //         toggleSound,
                      //       })
                      //     }
                      //   }
                    } else if (operationType === 'update' && foundTrip) {
                      if (userIsTripDriver({ trip, id: userInfo?._id }) || !isDriver(userInfo)) {
                        if (
                          isNewNote &&
                          ((!isCustomer(userInfo) &&
                            !isDriver(userInfo) &&
                            userInfo?._id !== isNewNote?.author?.id) ||
                            userIsTripDriver({ trip, id: userInfo?._id }))
                        ) {
                          notify({
                            msg: `${isNewNote?.content} ${
                              custFullName ? `- Client: ${custFullName}` : ''
                            } - ${isNewNote?.author?.name} ${formatDate({})}`,
                            type: 'success',
                            toastId: isNewNote?._id,
                            toggleSound,
                            autoClose: 180000,
                          })
                        }
                        if (
                          !isCustomer(userInfo) &&
                          isCustomerReady &&
                          !newDriver &&
                          !isNewNote
                          // &&
                          // foundTrip?.onTrip === trip?.onTrip
                        ) {
                          notify({
                            msg: `${custFullName || `Client`} is Ready for Pickup - ${formatDate(
                              {},
                            )}`,
                            type: 'success',
                            toastId: 'isCustomerReady',
                            toggleSound,
                            autoClose: 180000,
                          })
                        }
                        if (newDriver && userIsTripDriver({ trip, id: userInfo?._id })) {
                          notify({
                            msg: `New Trip ${
                              custFullName ? `for ${custFullName}` : ''
                            } at ${formatDate({
                              dateData: new Date(trip?.times[`expectedPickupTime1`] || Date.now),
                            })}`,
                            toastId: 'newtrip',
                            type: 'success',
                            toggleSound,
                          })
                        }
                      }
                      if (
                        trip.tripState === 'canceled' &&
                        !isCustomer(userInfo) &&
                        (!isDriver(userInfo) || userIsTripDriver({ trip, id: userInfo?._id }))
                      ) {
                        notify({
                          msg: `1 Trip ${
                            custFullName ? `for ${custFullName} is` : ''
                          } Canceled - ${formatDate({})}`,
                          type: 'error',
                          toastId: 'tripCanceled',
                          fail: true,
                          toggleSound,
                          autoClose: 180000,
                        })
                      }
                      // if (isCustomerReady && newDriver && newDriver === userInfo?._id) {
                      //   notify({
                      //     msg: `New Trip ${custFullName ? `- Client: ${custFullName}` : ''} ${formatDate(
                      //       {},
                      //     )}`,
                      //     type: 'success',
                      //     toastId: 'isNewDriver',
                      //   })
                      // }
                      // insertOrUpdateTrip()
                    }
                    // }

                    const type =
                      operationType === 'insert'
                        ? CREATE_TRIP_SUCCESS
                        : // : operationType === 'update'
                          UPDATE_TRIP_SUCCESS
                    // : DELETE_DESTINATION_SUCCESS
                    // const msg =
                    //   operationType === 'insert' ? 'Created' : operationType === 'delete' ? 'Deleted' : 'Updated'
                    dispatch({ type, payload: { trip } })
                    // }, 77)
                  } catch (err) {
                    handleErr({ err })
                  }
                } else {
                  const tIdx = tripsDataRef.current.findIndex((t) => t._id === trip._id)
                  const type =
                    tIdx >= 0
                      ? UPDATE_TRIP_SUCCESS
                      : isSameCustomerAndPk({ bookedTrip: trip, trips: tripsDataRef.current })
                      ? CREATE_TRIP_SUCCESS
                      : ''
                  if (type) dispatch({ type, payload: { trip } })
                }
              } else if (
                (!trip?.driver && searchTermsRef.current?.driver) ||
                (isDriver(userInfo) &&
                  trip?.driver !== userInfo?._id &&
                  tripsDataRef?.current?.find((t) => t?._id === trip?._id))
              ) {
                dispatch({ type: DELETE_SCHEDULED_TRIP_SUCCESS, payload: trip })
                dispatch({ type: DELETE_TRIP_SUCCESS, payload: trip })
              }
            }
          } catch (err) {
            clearInterval(locationIntervalRef.current)
            handleErr({ err })
          }
        })

        socket?.on(`usersList${id}`, async (userData) => {
          if (id) {
            const { user, operationType } = userData

            const isInFacility = someFacility({
              facility: user?.facility,
              user: userDataRef.current,
            })

            const type =
              operationType === 'insert'
                ? CREATE_USER_SUCCESS
                : operationType === 'update'
                ? UPDATE_USER_SUCCESS
                : !isInFacility
                ? DELETE_USER_SUCCESS
                : DELETE_USER_SUCCESS
            const msg =
              operationType === 'insert'
                ? 'Created'
                : operationType === 'delete' || !isInFacility
                ? 'Deleted'
                : 'Updated'
            const payload = { msg, user }
            // operationType === 'insert' || operationType === 'update' ? { msg, user } : {}

            if (
              userDataRef.current?._id === user?._id &&
              (await handleIDB?.getVal({ model: 'Auth', _id: 'userData' }))?.facility
                ?.sort()
                ?.toString() !== user?.facility?.sort()?.toString()
            ) {
              setTimeout(() => socket?.disconnect(), 77)
              setTimeout(() => socket?.connect(), 707)
            }

            dispatch({ type, payload })
          }
        })

        socket?.on(`destinationsList${id}`, (destData) => {
          if (id) {
            const { destination, operationType } = destData
            const type =
              operationType === 'insert'
                ? CREATE_DESTINATIONS_SUCCESS
                : operationType === 'update'
                ? UPDATE_DESTINATION_SUCCESS
                : DELETE_DESTINATION_SUCCESS
            const msg =
              operationType === 'insert'
                ? 'Created'
                : operationType === 'delete'
                ? 'Deleted'
                : 'Updated'
            dispatch({ type, payload: { msg, destination } })
            // callBack('ok')}
          }
        })

        socket?.on(`insurancesList${id}`, (dbPayload) => {
          if (id) {
            const { insurance, operationType } = dbPayload
            const type =
              operationType === 'insert'
                ? CREATE_INSURANCE_SUCCESS
                : operationType === 'update'
                ? UPDATE_INSURANCE_SUCCESS
                : DELETE_INSURANCE_SUCCESS
            const msg =
              operationType === 'insert'
                ? 'Created'
                : operationType === 'delete'
                ? 'Deleted'
                : 'Updated'
            dispatch({ type, payload: { msg, insurance } })
            // callBack('ok')}
          }
        })

        socket?.on(`brokersList${id}`, (dbPayload) => {
          if (id) {
            const { broker, operationType } = dbPayload
            const type =
              operationType === 'insert'
                ? CREATE_BROKER_SUCCESS
                : operationType === 'update'
                ? UPDATE_BROKER_SUCCESS
                : DELETE_BROKER_SUCCESS
            const msg =
              operationType === 'insert'
                ? 'Created'
                : operationType === 'delete'
                ? 'Deleted'
                : 'Updated'
            dispatch({ type, payload: { msg, broker } })
            // callBack('ok')}
          }
        })

        socket?.on(`vehiclesList${id}`, (vData) => {
          if (id) {
            const { vehicle, operationType } = vData

            const isInFacility = someFacility({
              facility: vehicle?.facility,
              user: userDataRef.current,
            })

            if (
              !isDriver(userInfo) ||
              (isDriver(userInfo) && (!vehicle?.driver || vehicle?.driver === userInfo?._id))
            ) {
              const type =
                operationType === 'insert'
                  ? CREATE_VEHICLE_SUCCESS
                  : operationType === 'update'
                  ? UPDATE_VEHICLE_SUCCESS
                  : !isInFacility
                  ? DELETE_VEHICLE_SUCCESS
                  : DELETE_VEHICLE_SUCCESS
              const msg =
                operationType === 'insert'
                  ? 'Created'
                  : operationType === 'delete' || !isInFacility
                  ? 'Deleted'
                  : 'Updated'
              dispatch({ type, payload: { msg, vehicle } })
            }
          }
        })

        socket?.on(`inspectionsList${id}`, (insData) => {
          if (id) {
            const { inspection, operationType } = insData
            if (
              (!isDriver(userInfo) && !isCustomer(userInfo)) ||
              (isDriver(userInfo) &&
                inspection?.vehicle ===
                  vehiclesDataRef?.current?.find((v) => v?.driver === userInfo?._id)?._id)
            ) {
              const type =
                operationType === 'insert'
                  ? CREATE_INSPECTION_SUCCESS
                  : operationType === 'update'
                  ? UPDATE_INSPECTION_SUCCESS
                  : ''
              const msg =
                operationType === 'insert' ? 'Created' : operationType === 'update' ? 'Updated' : ''
              dispatch({ type, payload: { msg, inspection } })
            }
          }
        })

        if (isAdmin(userInfo)) {
          socket?.on(`brokersPricingList${id}`, (insData) => {
            if (id) {
              const { brokerPricing, operationType } = insData
              dispatch({
                type: UPDATE_BROKER_PRICING_SUCCESS,
                payload: { msg: 'Updated', brokerPricing },
              })
            }
            // }
          })
        }

        socket?.on(`signaturesList${id}`, (insData) => {
          if (id) {
            const { signature, operationType } = insData
            // if (
            //   (!isDriver(userInfo) && !isCustomer(userInfo)) ||
            //   (isDriver(userInfo) &&
            //     inspection?.vehicle ===
            //       vehiclesDataRef?.current?.find((v) => v?.driver === userInfo?._id)?._id)
            // ) {
            const type = UPDATE_SIGNATURE_SUCCESS
            const msg =
              operationType === 'insert' ? 'Created' : operationType === 'update' ? 'Updated' : ''
            dispatch({ type, payload: { msg, signature } })
          }
          // }
        })

        socket?.on(`productsList${id}`, (pData) => {
          if (id) {
            const { product, operationType } = pData
            const type =
              operationType === 'insert'
                ? CREATE_PRODUCT_SUCCESS
                : operationType === 'update'
                ? UPDATE_PRODUCT_SUCCESS
                : operationType === 'delete'
                ? DELETE_PRODUCT_SUCCESS
                : ''
            const msg =
              operationType === 'insert' ? 'Created' : operationType === 'update' ? 'Updated' : ''
            dispatch({ type, payload: { msg, product } })
          }
        })

        // socket?.on(`clocksList${id}`, (dbPayload) => {
        //   const { success, clock, errors = [] } = dbPayload
        //   console.log('clockInOut', dbPayload)
        //   if (success) {
        //     dispatch({ type: CREATE_CLOCK_SUCCESS, payload: dbPayload })
        //   } else {
        //     // toast.error(`Error`, { toastId: userInfo._id })
        //     dispatch({ type: CREATE_CLOCK_FAIL, payload: errors })
        //   }
        // })
        socket?.on(`clockInOut-received${userInfo?._id}`, (dbPayload) => {
          const { success, clock, errors } = dbPayload
          console.log('clockInOut', dbPayload)
          if (success) {
            dispatch({ type: CREATE_CLOCK_SUCCESS, payload: dbPayload })
          } else {
            // toast.error(`Error`, { toastId: userInfo._id })
            dispatch({ type: CREATE_CLOCK_FAIL, payload: errors })
          }
        })
        socket?.on(`clockInOut-received${companyInfo?._id}`, (dbPayload) => {
          const { success, clock, errors } = dbPayload
          if (success) {
            dispatch({ type: UPDATE_CLOCK_SUCCESS, payload: dbPayload })
          } else {
            // toast.error(`Error`, { toastId: userInfo._id })
            dispatch({ type: UPDATE_CLOCK_FAIL, payload: errors })
          }
        })

        socket?.on(`createAbsense-received${id}`, (dbPayload) => {
          const { success, absense, errors } = dbPayload
          if (success) {
            if (isDriver(userInfo)) {
              if (String(userInfo._id) === String(absense.user._id)) {
                dispatch({ type: CREATE_ABSENSE_SUCCESS, payload: absense })
              }
            } else {
              dispatch({ type: CREATE_ABSENSE_SUCCESS, payload: absense })
            }
          } else {
            toast.error(`Error: ${errors?.length ? errors[0] : ''}`, { toastId: userInfo.company })
            dispatch({ type: CREATE_ABSENSE_FAIL, payload: errors })
          }
        })
        socket?.on(`updateAbsense-received${id}`, (dbPayload) => {
          const { success, absense, errors } = dbPayload
          if (success) {
            if (isDriver(userInfo)) {
              if (String(userInfo._id) === String(absense.user._id)) {
                dispatch({ type: UPDATE_ABSENSE_SUCCESS, payload: absense })
              }
            } else {
              dispatch({ type: UPDATE_ABSENSE_SUCCESS, payload: absense })
            }
          } else {
            // toast.error(`Error`, { toastId: userInfo.company })
            dispatch({ type: UPDATE_ABSENSE_FAIL, payload: errors })
          }
        })
        socket?.on(`createHoliday-received${id}`, (dbPayload) => {
          const { success, holiday, errors } = dbPayload
          if (success) {
            if (!isDriver(userInfo)) {
              dispatch({ type: CREATE_HOLIDAY_SUCCESS, payload: holiday })
            }
          } else {
            // toast.error(`Error`, { toastId: userInfo.company })
            dispatch({ type: CREATE_HOLIDAY_FAIL, payload: errors })
          }
        })
        socket?.on(`updateHoliday-received${id}`, (dbPayload) => {
          const { success, holiday, errors } = dbPayload
          if (success) {
            if (isDriver(userInfo)) {
              if (String(userInfo?._id) === String(holiday?.user?._id)) {
                dispatch({ type: UPDATE_HOLIDAY_SUCCESS, payload: holiday })
              }
            } else {
              dispatch({ type: UPDATE_HOLIDAY_SUCCESS, payload: holiday })
            }
          } else {
            // toast.error(`Error`, { toastId: userInfo.company })
            dispatch({ type: UPDATE_HOLIDAY_FAIL, payload: errors })
          }
        })

        socket?.on(`remindersList${id}`, (dbPayload) => {
          if (id) {
            const { reminder, operationType } = dbPayload
            if (
              !reminder?.visibleTo?.find((x) => x === userInfo?._id || x === userInfo?.level) ||
              (reminder?.scheduleEnd &&
                Date.parse(reminder.scheduleEnd) >
                  Date.parse(searchTermsRef?.current?.constraint?.split('_')[1]))
            ) {
              return dispatch({
                type: DELETE_REMINDER_SUCCESS,
                payload: { msg: 'Deleted', reminder },
              })
            }
            const type =
              operationType === 'insert'
                ? CREATE_REMINDER_SUCCESS
                : operationType === 'update'
                ? UPDATE_REMINDER_SUCCESS
                : DELETE_REMINDER_SUCCESS
            const msg =
              operationType === 'insert'
                ? 'Created'
                : operationType === 'delete'
                ? 'Deleted'
                : 'Updated'
            dispatch({ type, payload: { msg, reminder } })
            // callBack('ok')}
          }
        })

        socket?.on(`companySettingsToggled${id}`, (vData) => {
          if (id) {
            const { companySettings, operationType } = vData
            dispatch({
              type: GET_COMPANY_SETTINGS_SUCCESS,
              payload: { msg: 'ok', companySettings },
            })
          }
        })
      }

      return () => {
        clearInterval(locationIntervalRef.current)
        socket?.disconnect && socket?.disconnect()
      }
    } catch (err) {
      clearInterval(locationIntervalRef.current)
      handleErr({ err })
    }
  }, [userInfo, socket, companyInfo?._id])

  const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
    try {
      console.log(error, info)
      const err = `${JSON.stringify(error)} - ${JSON.stringify(info)}`
      handleErr({ err })
    } catch (err) {
      handleErr({ err })
    }
  }

  return (
    <ErrorBoundary
      onError={logError}
      fallback={
        <div className="errorBoundary">
          <p style={{ color: 'red' }}>Oops! {userDataRef?.current?.firstName || ''}</p>
          <p style={{ color: 'red' }}>Something Went wrong!!!</p>
          <a
            // style={{ margin: '0 5px' }}
            href={window.location.protocol + '//' + window.location.host}
          >
            🏠 GO TO START
          </a>
          <a
            // style={{ margin: '0 0 0 5px' }}
            href="mailto:support@xperdriver.com"
          >
            ✉️ Support
          </a>
        </div>
      }
    >
      {showFullLoader}
      {playSound}
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<FullLoader />}>
          <Routes>
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route
              exact
              path="/login"
              name="Login Page"
              element={<Login companyByDomain={companyInfo || company?.data} />}
            />
            <Route
              exact
              path="/forgetpassword"
              name="Send Code Page"
              element={<Sendcode companyByDomain={companyInfo || company?.data} />}
            />
            <Route
              // exact
              path="/setnewpassword"
              name="New Password"
              element={<Setnewpassword companyByDomain={companyInfo || company?.data} />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/widget" name="Widget Page" element={<WidgetContainer />} />

            <Route
              path="*"
              name="Home"
              element={
                <DefaultLayout socket={socket} companyByDomain={companyInfo || company?.data} />
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

// App.propTypes = {
//   socket: PropTypes.any,
// }
export default React.memo(App)
